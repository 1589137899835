import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../styles/PreviousGamesPopup.css';
import { useTranslation } from "react-i18next";
import { getAvailableGames, getAllResults } from "../api/apiCalls";
import { useParams } from "react-router-dom";

function PreviousGamesPopup({ open, handleClose }) {
    const { t } = useTranslation('common');
    const initialMode = useParams().mode;

    const [selectedMode, setSelectedMode] = useState(initialMode);
    const [currentPage, setCurrentPage] = useState(0);
    const [availableDays, setAvailableDays] = useState([]); // almacena todas las fechas
    const [pageDays, setPageDays] = useState([]); // fechas de la página actual
    const [totalPages, setTotalPages] = useState(0);
    const [allResults, setAllResults] = useState([]);
    const daysPerPage = 20;

    const winCount = allResults.filter(r =>
        r.game_mode.replace("_", "-") === selectedMode && r.state === 'win'
    ).length;

    const handleButtonClick = (day) => {
        handleClose();
        window.location.href = `/${selectedMode}/${day}`;
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleModeChange = (event) => {
        const newMode = event.target.value;
        setSelectedMode(newMode);
    };

    useEffect(() => {
        getAvailableGames().then(data => {
            setAvailableDays(data);
            setTotalPages(Math.ceil(data.length / daysPerPage));
        }).catch(error => console.log(error));

        getAllResults().then(data => {
            setAllResults(data);
        }).catch(error => console.log(error));
    }, []);

    // Update pageDays every time currentPage or availableDays changes
    useEffect(() => {
        const startIndex = currentPage * daysPerPage;
        const newPageDays = availableDays.slice(startIndex, startIndex + daysPerPage);
        setPageDays(newPageDays);
    }, [currentPage, availableDays]);

    const winColor = "#4caf50";
    const loseColor = "#ff5e62";
    const pendingColor = "#FFEB3B";
    const defaultColor = "#9e9e9e";

    return (
        <Dialog open={open} onClose={handleClose} classes={{ paper: 'popup-dialog' }}>
            <DialogTitle className="popup-title">
                {t('previousGamesPopup.title')}
                <IconButton className="close-button" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className="popup-header">
                    <div className="mode-select-container">
                        <FormControl variant="outlined" size="small">
                            <InputLabel id="mode-select-label">{t('previousGamesPopup.mode')}</InputLabel>
                            <Select
                                labelId="mode-select-label"
                                value={selectedMode}
                                onChange={handleModeChange}
                                label="Mode"
                            >
                                <MenuItem value="opening">Opening</MenuItem>
                                <MenuItem value="hardcore-opening">Hardcore Opening</MenuItem>
                                <MenuItem value="ending">Ending</MenuItem>
                                <MenuItem value="hardcore-ending">Hardcore Ending</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="win-counter">
                        <span className="win-count">{"🏆" + winCount}</span>
                    </div>
                </div>
                <div className="pagination-container">
                    {pageDays && pageDays.length > 0 ? (
                        pageDays.map((day, index) => {
                            const result = allResults?.find(r => r.date === day && r.game_mode.replace("_", "-") === selectedMode);
                            let backgroundColor = defaultColor;
                            if (result) {
                                if (result.state === 'win') {
                                    backgroundColor = winColor;
                                } else if (result.state === 'lose') {
                                    backgroundColor = loseColor;
                                } else if (result.state === 'pending') {
                                    backgroundColor = pendingColor;
                                }
                            }
                            return (
                                <Button
                                    key={index}
                                    variant="contained"
                                    onClick={() => handleButtonClick(day)}
                                    style={{ margin: '5px', backgroundColor, color: 'white' }}
                                    className="day-button"
                                >
                                    {day}
                                </Button>
                            );
                        })
                    ) : (
                        <p>{t("previousGamesPopup.noGames")}</p>
                    )}
                </div>
            </DialogContent>
            <DialogActions className="pagination-actions">
                <IconButton onClick={handlePrevPage} disabled={currentPage === 0}>
                    <ArrowBackIcon />
                </IconButton>
                <span className="page-info">
                    {t("previousGamesPopup.page")
                        .replace("{{page}}", currentPage + 1)
                        .replace("{{total}}", totalPages)}
                </span>
                <IconButton onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                    <ArrowForwardIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
}

export default PreviousGamesPopup;