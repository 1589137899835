import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

function CookieConsent() {
    const { t, i18n } = useTranslation('common');
    const [openPrivacy, setOpenPrivacy] = useState(false);

    useEffect(() => {
        if (window.cookieconsent) {
            // Elimina cualquier banner previo
            const ccWindow = document.querySelector('.cc-window');
            if (ccWindow) {
                ccWindow.parentNode.removeChild(ccWindow);
            }

            window.cookieconsent.initialise({
                palette: {
                    popup: { background: "#000" },
                    button: { background: "#f1d600" }
                },
                theme: "classic",
                content: {
                    message: t('cookieConsent.message'),
                    dismiss: t('cookieConsent.dismiss'),
                    link: t('cookieConsent.link'),
                    href: "#" // Se sigue usando "#"
                },
                onInitialise: function (status) {
                    if (this.hasConsented()) {
                        window.gtag('consent', 'update', {
                            'analytics_storage': 'granted'
                        });
                    }
                },
                onStatusChange: function (status, chosenBefore) {
                    if (this.hasConsented()) {
                        window.gtag('consent', 'update', {
                            'analytics_storage': 'granted'
                        });
                    } else {
                        window.gtag('consent', 'update', {
                            'analytics_storage': 'denied'
                        });
                    }
                }
            });

            // Listener para el enlace de privacidad
            setTimeout(() => {
                document.addEventListener('click', function (e) {
                    if (e.target && e.target.classList.contains('cc-link')) {
                        e.preventDefault();
                        setOpenPrivacy(true);
                    }
                });
            }, 500);
        }
    }, [t, i18n.language]); // Agregamos i18n.language como dependencia

    return (
        <>
            <Dialog open={openPrivacy} onClose={() => setOpenPrivacy(false)}>
                <DialogTitle>{t('cookieConsent.link')}</DialogTitle>
                <DialogContent>
                    {t('cookieConsent.privacyText') ||
                        "Aquí irá la información sobre cómo utilizamos las cookies y tu privacidad."}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPrivacy(false)}>
                        {t('buttons.close') || "Cerrar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CookieConsent;