import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import DehazeRoundedIcon from '@mui/icons-material/DehazeRounded';
import { useTranslation } from "react-i18next";
import '../styles/FadeMenu.css';
import ReloadLink from './ReloadLink';
import { usePlayedModes } from '../App';
import Badge from '@mui/material/Badge';
import PreviousGamesPopup from "./PreviousGamesPopup";
import calendarIcon from '../assets/pixelart_icon/calendar_icon.png';
import { useState } from "react"


export default function FadeMenu({ options }) {
    const { playedModes } = usePlayedModes();
    const { t } = useTranslation('common');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <DehazeRoundedIcon id="icon-menu" />
            </IconButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {options.map(({ id, name, icon, link }, index) => (
                    <MenuItem key={index}>
                        <ReloadLink className="menu-link" to={link}>
                            <Badge badgeContent={!Object.keys(playedModes).includes(id) ? "!" : 0} invisible={Object.keys(playedModes).includes(id)} color='error'>
                                <img className="menu-icon" src={icon} alt={name + " icon"} />
                            </Badge>
                            {name}
                        </ReloadLink>
                    </MenuItem>
                ))}

                <MenuItem onClick={() => setPopupOpen(true)}>
                    <div className="menu-link">
                        <Badge badgeContent={0} color="error">
                            <img className="menu-icon" src={calendarIcon} alt="Calendar icon" style={{ width: "40px", padding: "0px 5px 0px 7px" }} />
                        </Badge>
                        {t("previousGames")}
                    </div>
                </MenuItem>

                <PreviousGamesPopup
                    open={popupOpen}
                    handleClose={() => setPopupOpen(false)}
                />
            </Menu>
        </div >
    );
}