import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Game from "./components/Game";
import Results from "./components/Results";
import { createContext, useContext, useState } from "react";
import './styles/utils.css';
import ReactGA from 'react-ga4';
import CookieConsent from "./components/CookieConsent";

ReactGA.initialize("G-DXNQ33D5NK");

export const appContext = createContext();

export function usePlayedModes() {
  return useContext(appContext);
}

function App() {
  const [playedModes, setPlayedModes] = useState([]);

  const value = {
    playedModes,
    setPlayedModes,
  };

  ReactGA.send({ hitType: "pageview", page: window.location.pathname })

  return (
    <>
      <CookieConsent />
      <appContext.Provider value={value}>
        <Routes>
          {/* Redirige la ruta raíz a /opening */}
          <Route path="/" element={<Navigate to="/opening" replace />} />

          {/* La ruta principal ahora tiene el parámetro mode */}
          <Route path="/:mode" element={<Navbar />}>
            <Route index element={<Game />} />
            <Route path="results" element={<Results />} />
            <Route path="results/:date" element={<Results />} />
            <Route path=":date" element={<Game />} />
          </Route>
        </Routes>
      </appContext.Provider>
    </>
  );
}

export default App;
