import React from 'react';

function ReloadLink({ to, children, ...props }) {
    const handleClick = (e) => {
        e.preventDefault();
        window.location.href = to;
    };

    return (
        <a href={to} onClick={handleClick} {...props}>
            {children}
        </a>
    );
}

export default ReloadLink;